import React from 'react';
import styled from 'styled-components';
import Typist from 'react-typist';
import 'react-typist/dist/Typist.css';

import Section from '../components/section';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { media } from '../utils/media';

const Wrapper = styled(Section)`
  max-width: 800px;
  margin: 0px auto;
  display: flex;
  justify-content: center;
  align-items: left;
  flex-direction: column;
  font-size: 14px;
  letter-spacing: 1px;
  padding-top: 13rem !important;
  ${media.tablet`
    padding: 0px 20px;
   
  `};
  ul {
    margin-bottom: 40px;
  }
  
  a:hover{
    color: #6dc9c8;
  }
`;

const Text = styled.h1`
  color: #6dc9c8;
  text-transform: uppercase;
  font-size: 25px;
  letter-spacing: 5px;
  line-height: 30px;
  text-align: center;
  ${media.tablet`
    font-size: 23px;
  `};
`;

const More = styled.div`
  width: 100%;
  position: relative;
  margin: 50px 0px;
  text-align: center;
  a {
    color: #3C3C3E;
    z-index: 21;
    font-size: 13px;
    letter-spacing: 4px;
    text-decoration: none;
    text-align: center;
    text-transform: uppercase;
    font-weight: 900;
    left: calc(50% + 3px);
    width: 100%;
    transform: translate(-50%, 0%);
    transition: all -0.3s;
    div {
      position: absolute;
      z-index: -1;
      left: 50%;
      transform: translate(-50%, 0%);
      top: 15px;
      width: 200px;
      height: 20px;
      background-color: #6dc9c8;
    }
  }
`;

const Contact = () => (
  <Layout>
    <SEO title='Project' />
    <Wrapper>
    <h2>Dreamy Garden</h2> <br/><br/>
    <p>another mobile app which improves your sleep habit, except for it works this time.</p>
      <br/>
      <p>Subscribe to get early access</p>
  

      
    </Wrapper>
  </Layout>
);

export default Contact;
